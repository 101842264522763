import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ModalController, IonSlides } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

//services
import { AuthService } from '@services/auth-service/auth-service';

// validators
import { CustomValidators } from '@validators/custom-validators';

//gui
import { GUI } from '@gui/gui';

//model
import { Account } from '@model/account';
import { User } from '@model/user';
import { MyError } from '@model/error';


@Component({
  selector: 'page-create-account',
  templateUrl: './create-account.html',
  styleUrls: ['./create-account.scss'],
})
export class CreateAccountPage implements OnInit, AfterViewInit {
  confirmationPassword = '';
  createAccountForm: FormGroup;
  @ViewChild('slider', { static: false }) slider: IonSlides;
  showPasswordInfo = false;

  account: Account;
  user: User;

  constructor(
    private formBuilder: FormBuilder,
    private gui: GUI,
    private authService: AuthService,
    private modalCtrl: ModalController) {

    this.account = new Account();
    this.user = new User();
  }

  ngOnInit() {
    this.createAccountForm = this.formBuilder.group({
      accountData: this.formBuilder.group({
        email: ['', [Validators.required, Validators.maxLength(50), CustomValidators.validateEmail(true)]],
        password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(30)]],
        confirmationPassword: ['', [Validators.required, Validators.maxLength(30)]]
      }, { validator: this.validateAccountData.bind(this) }),
      userData: this.formBuilder.group({
        displayName: ['', Validators.required]
        //telefono: ['', [Validators.required, CustomValidators.validateTrimString()]]
      })
    });
  }

  ngAfterViewInit() {
    this.slider.lockSwipes(true);
  }

  close(created: boolean = false) {
    this.modalCtrl.dismiss(created);
  }

  async back() {
    await this.goToSlide(0);
  }

  async next() {
    await this.goToSlide(1);
  }

  onFocusPassword(e) {
    this.showPasswordInfo = true;
  }

  onBlurPassword(e) {
    this.showPasswordInfo = false;
  }

  async createUser() {
    const loading = await this.gui.presentCircleLoading();

    this.account.email = this.account.email.trim();
    this.user.account = this.account;
    this.authService.createAccount(this.user)
      .then(user => {
        this.authService.sendEmailVerification().then(() => {
          loading.dismiss().then(() => {
            this.gui.showAlertMessage(
              'Cuenta creada correctamente',
              `Te hemos enviado un email de verificación a ${this.account.email}. Recuerda revisar tu carpeta de Spam.`,
              () => {
                this.close(true);
              });
          });
        });
      })
      .catch((error: MyError) => {
        loading.dismiss().then(() => {
          this.gui.showAlertError(error.message);
        });
      });
  }

  async goToSlide(slide: number) {
    await this.slider.lockSwipes(false);
    await this.slider.slideTo(slide);
    await this.slider.lockSwipes(true);
  }

  private validateAccountData(group: FormGroup) {
    const password = group.get('password').value;
    const confirmationPassword = group.get('confirmationPassword').value;

    return password !== confirmationPassword ? { validatePassword: false } : null;
  }

}
