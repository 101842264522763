import { Component } from '@angular/core';

import { NavController, Platform, Config } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class DiHolaBotMakerComponent {

  constructor(
    private navCtrl: NavController,
    private config: Config,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar
  ) {

    //this.navCtrl.navigateRoot(this.rootPage);
    this.initializeApp();
  }

  private initializeApp() {
    this.platform.ready().then(async () => {
      if (!this.platform.is('mobile')) {
        // quitar la animación entre página y página
        //this.config.set('animated', false);
      }

      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }
}
