import { Account } from '@model/account';
import { Storable } from '@model/storable';

//utils
import * as _ from '@utils/micro-lodash';
import { Roles } from '@model/roles';

export interface UserQueryResult {
  found: boolean;
  user?: UserInfo;
}

export interface UserInfo {
  id: string;
  displayName: string;
  email: string;
}

export class User implements Storable {
  id: string;
  createdAt?: any;
  updatedAt?: any;

  displayName: string;
  account: Account;

  roles: Roles;

  constructor(user?: any) {

    if (user) {
      this.id = user.id ? user.id : user.uid;

      if (_.isPresent(user.createdAt)) {
        this.createdAt = user.createdAt;
      }

      if (_.isPresent(user.updatedAt)) {
        this.updatedAt = user.updatedAt;
      }

      this.displayName = user.displayName;
      this.roles = new Roles(user.roles);

      this.account = new Account();
      this.account.email = user.email;
      this.account.emailVerified = _.isPresent(user.emailVerified) ? user.emailVerified : false;
      this.account.providerId = !_.isEmpty(user.providerData) ? user.providerData[0].providerId : null;

    } else {
      this.roles = new Roles();
    }
  }

  getStorable(update?: boolean): any {
    const storable: any = {
      email: _.isPresent(this.account.email) ? this.account.email : null,
      displayName: this.displayName
    };

    if (!update) {
      storable.roles = this.roles.getStorable(update);
    }

    return storable;
  }

  public static getUserId(userOrUserId: User | string): string {
    return userOrUserId instanceof User ? userOrUserId.id : userOrUserId;
  }
}
