import { Storable } from '@model/storable';

export interface IRoles {
    client?: boolean;
    admin?: boolean;
}

export class Roles implements Storable {
    public static ClientRole = {
        client: true
    };

    public static AdminRole = {
        admin: true
    };

    id: string;

    client: boolean;
    admin: boolean;

    constructor(roles?: any) {
        if (roles) {
            this.id = roles.id;
            this.client = roles.client;
            this.admin = roles.admin;
        } else {
            this.id = '';
            this.client = true;
            this.admin = false;
        }
    }

    getStorable(update?: boolean): any {
        return {
            client: this.client,
            admin: this.admin
        };
    }

    public equals(roles: IRoles): boolean {
        return roles &&
            roles.client === this.client &&
            roles.admin === this.admin;
    }

    public some(roles: IRoles): boolean {
        return roles && (
            roles.client === this.client ||
            roles.admin === this.admin);
    }

}
