import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthorizatedGuard } from '@services/guards/authorizated-guard';

const routes: Routes = [
  { path: 'login', data: { title: 'Login' }, loadChildren: () => import('./pages/auth/auth.module').then(m => m.MyAuthModule) },
  { path: 'home', redirectTo: 'home/', pathMatch: 'full' },
  { path: 'home/:loading', canActivate: [AuthorizatedGuard], loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule) },
  { path: 'editor/:botId/:diagramId', canActivate: [AuthorizatedGuard], loadChildren: () => import('./pages/editor/editor.module').then(m => m.EditorPageModule) },
  { path: 'chats', canActivate: [AuthorizatedGuard], loadChildren: () => import('./pages/chats/chats.module').then(m => m.ChatsPageModule) },
  { path: 'config', canActivate: [AuthorizatedGuard], loadChildren: () => import('./pages/config/config.module').then(m => m.ConfigPageModule) },
  { path: 'keys', redirectTo: 'keys/', pathMatch: 'full' },
  { path: 'keys/:backTo', canActivate: [AuthorizatedGuard], loadChildren: () => import('./pages/keys/keys.module').then(m => m.KeysPageModule) },
  { path: 'groups', canActivate: [AuthorizatedGuard], loadChildren: () => import('./pages/groups/groups.module').then(m => m.GroupsPageModule) },
  { path: 'schedule', loadChildren: () => import('./pages/schedule/schedule.module').then( m => m.SchedulePageModule) },
  { path: 'optin', redirectTo: 'optin/', pathMatch: 'full' },
  { path: 'optin/:seq', loadChildren: () => import('./pages/optin/optin.module').then( m => m.OptinPageModule) },
  { path: 'templates', redirectTo: 'templates/', pathMatch: 'full' },
  { path: 'templates/:seq', canActivate: [AuthorizatedGuard], loadChildren: () => import('./pages/templates/templates.module').then(m => m.TemplatesPageModule) },
  { path: 'profile', canActivate: [AuthorizatedGuard], loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfilePageModule) },
  { path: '', redirectTo: 'home/', pathMatch: 'full' },
  { path: '**', redirectTo: 'home/' }
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes,
    {
      enableTracing: false, // <-- cambiar a true para: debugging purposes only
      preloadingStrategy: PreloadAllModules
    }
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
