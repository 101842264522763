import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

//providers
import { AuthService } from '@services/auth-service/auth-service';

// validators
import { CustomValidators } from '@validators/custom-validators';

//gui
import { GUI } from '@gui/gui';

//model
import { MyError } from '@model/error';

@Component({
  selector: 'page-reset-password',
  templateUrl: './reset-password.html',
  styleUrls: ['./reset-password.scss'],
})
export class ResetPasswordPage implements OnInit {
  resetPasswordForm: FormGroup;
  email = '';

  constructor(
    private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private gui: GUI) {
  }

  ngOnInit() {
    this.resetPasswordForm = this.formBuilder.group({
      email: [this.email, [Validators.required, Validators.maxLength(50), CustomValidators.validateEmail(true)]]
    });
  }

  close() {
    this.modalCtrl.dismiss();
  }

  async resetPassword() {
    const loading = await this.gui.presentCircleLoading();

    this.email = this.email.trim();
    this.authService.resetPassword(this.email)
      .then(() => {
        loading.dismiss().then(() => {
          this.gui.showAlertMessage(
            this.email,
            'Te hemos enviado un correo con las indicaciones para restablecer la contraseña',
            () => this.close()
          );
        });
      }).catch((error: MyError) => {
        loading.dismiss().then(() => {
          this.gui.showAlertError(error.message);
        });
      });
  }

}
