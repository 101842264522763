import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { Routes, RouterModule } from '@angular/router';

//components
import { SideMenuComponent } from '@components/my-side-menu/my-side-menu';

//modules
import { FixDirectivesModule } from '@directives/fix-directives.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxPopperModule } from 'ngx-popper';

const CUSTOM_COMPONENTS = [
  SideMenuComponent
];

const CUSTOM_ENTRY_COMPONENTS = [
  SideMenuComponent
];

const routes: Routes = [];

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FixDirectivesModule,
    FontAwesomeModule,
    NgxPopperModule.forRoot({
      trigger: 'hover'
    }),
    RouterModule.forChild(routes)
  ],
  declarations: CUSTOM_COMPONENTS,
  exports: CUSTOM_COMPONENTS,
  entryComponents: CUSTOM_ENTRY_COMPONENTS
})
export class MySideMenuComponentModule { }
