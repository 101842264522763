export function unsubscribe(subscriptions: any[]) {
    subscriptions.forEach(s => {
        if (typeof s.unsubscribe === 'function') {
            s.unsubscribe();
        }
    });
}

export function nearestMinutes(interval, someMoment) {
    const roundedMinutes = Math.round(someMoment.clone().minute() / interval) * interval;
    return someMoment.clone().minute(roundedMinutes).second(0);
}
