import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { NavController } from '@ionic/angular';
import { AuthService } from '@services/auth-service/auth-service';

@Injectable({
  providedIn: 'root'
})
export class AuthorizatedGuard implements CanActivate {

  constructor(
    private navCtrl: NavController,
    private authService: AuthService) { }

  async canActivate() {
    const isLoggedIn = await this.authService.isLoggedIn();
    if (!isLoggedIn) {
      // logged in so return true
      this.navCtrl.navigateRoot('login');
    }

    return isLoggedIn;
  }
}
