export class Account {
  email: string;
  password: string;
  emailVerified: boolean;
  providerId: string;

  constructor() {
    this.email = '';
    this.password = '';
    this.emailVerified = false;
  }
}
