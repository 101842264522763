import { Component, OnDestroy, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { Router, ActivationEnd, PRIMARY_OUTLET } from '@angular/router';
import { MenuController, NavController } from '@ionic/angular';

//services
import { AuthService } from '@services/auth-service/auth-service';

//model
import { User } from '@model/user';

//utils
import * as Utils from '@utils/utils';
import * as _ from '@utils/micro-lodash';

//gui
import { GUI } from '@gui/gui';

@Component({
  selector: 'my-side-menu',
  templateUrl: './my-side-menu.html',
  styleUrls: ['./my-side-menu.scss']
})
export class SideMenuComponent implements OnInit, OnDestroy {
  @Input() contentId: string;

  user: User;

  selected: string;
  disabledPane = false;

  subscriptions = [];

  constructor(
    private gui: GUI,
    private router: Router,
    private menu: MenuController,
    private navCtrl: NavController,
    private authService: AuthService) { }

  ngOnInit() {
    this.menu.enable(true, 'custom');

    this.subscriptions.push(
      this.router.events.subscribe(e => {
        if (e instanceof ActivationEnd) {
          const path = e.snapshot.routeConfig && e.snapshot.routeConfig.path;
          if (!_.isEmpty(path)) {
            const selectedPage = this.getSelected();
            this.selected = this.mapSelected(selectedPage);
            this.updatePath(this.selected);
          }
        }
      })
    );

    this.subscriptions.push(
      this.authService.getUserLoggedIn$().subscribe(user => {
        this.user = user;
      })
    );

  }

  onClickHome() {
    this.goPage('home');
  }

  onClickChats() {
    this.goPage('chats');
  }

  onClickSchedule() {
    this.goPage('schedule');
  }

  onClickConfig() {
    this.goPage('config');
  }

  onClickEditor() {
    this.goPage('editor');
  }

  onClickProfile() {
    this.goPage('profile');
  }

  onClickEnter() {
    this.navCtrl.navigateRoot('login');
  }

  onClickExit() {
    this.gui.showConfirmMessage(
      'CERRAR SESIÓN',
      '¿Desea cerrar sesión?',
      () => {
        this.gui.presentCircleLoadingAndWait(
          async () => {
            await this.authService.signOut();
            this.navCtrl.navigateRoot('/login');
          }
        );
      });
  }

  //privates
  private updatePath(path: string) {
    this.disabledPane = ['login'].some(p => path.startsWith(p));
  }

  private goPage(page: string) {
    this.selected = page;
    this.navCtrl.navigateRoot(this.selected);
  }

  private getSelected(): string {
    const urlTree = this.router.parseUrl(this.router.url);
    return urlTree.root.children[PRIMARY_OUTLET].segments[0].path;
  }

  private mapSelected(selected: string): string {
    if(['keys', 'groups'].includes(selected)) {
      return 'config';
    }

    if(['optin', 'templates'].includes(selected)) {
      return 'schedule';
    }

    return selected;
  }

  ngOnDestroy() {
    Utils.unsubscribe(this.subscriptions);
  }
}
