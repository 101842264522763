import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { environment } from '@environments/environment';

//services
import { ModalUtil } from '@utils/modal';

//modules
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';

//firebase
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireFunctionsModule } from '@angular/fire/functions';

//config
import {
  URL_BASE_REST_VALUE,
  URL_BASE_REST
} from '@config/config';

import {
  faBars,
  faPlusSquare,
  faMinusSquare,
  faTimesCircle,
} from '@fortawesome/pro-solid-svg-icons';

import {
  faSignInAlt,
  faSignOutAlt,
  faUserAlt,
  faUserFriends,
  faHome,
  faProjectDiagram,
  faAngleDown,
  faThumbsDown,
  faFileEdit,
  faRobot,
  faText,
  faHashtag,
  faTag,
  faGlobeAmericas,
  faArrowToRight,
  faArrowToLeft,
  faPlus,
  faMinus,
  faListAlt,
  faKey,
  faCommentDots,
  faFileCheck,
  faFileExcel,
  faMobile,
  faCalendarAlt,
  faCogs,
  faUserClock,
  faUserCheck,
  faUserTimes,
  faPaste
} from '@fortawesome/pro-regular-svg-icons';

//DiHola
import { DiHolaBotMakerComponent } from './app.component';

//SideMenu
import { MySideMenuComponentModule } from '@components/my-side-menu/my-side-menu.module';
import { loginHomeFactory } from '@pages/auth/login-home-factory';

//routing
import { AppRoutingModule } from './app-routing.module';

@NgModule({
  declarations: [DiHolaBotMakerComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MySideMenuComponentModule,
    AppRoutingModule,
    FontAwesomeModule,
    NgJsonEditorModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule, //.enablePersistence(), //https://stackoverflow.com/questions/46868580/firestore-using-cache-until-online-content-updates
    AngularFireStorageModule,
    AngularFireFunctionsModule,
    IonicModule.forRoot({
      mode: 'md',
      backButtonText: ''
    })
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: URL_BASE_REST, useValue: URL_BASE_REST_VALUE.replace(`{env}`, environment.environment) }
  ],
  bootstrap: [DiHolaBotMakerComponent]
})
export class AppModule {
  constructor(
    modal: ModalUtil,
    library: FaIconLibrary) {
    library.addIcons(
      faSignInAlt,
      faSignOutAlt,
      faHome,
      faProjectDiagram,
      faUserAlt,
      faUserFriends,
      faAngleDown,
      faThumbsDown,
      faFileEdit,
      faRobot,
      faBars,
      faText,
      faHashtag,
      faTag,
      faGlobeAmericas,
      faArrowToRight,
      faArrowToLeft,
      faPlus,
      faMinus,
      faListAlt,
      faKey,
      faPlusSquare,
      faMinusSquare,
      faTimesCircle,
      faCommentDots,
      faFileCheck,
      faFileExcel,
      faMobile,
      faCalendarAlt,
      faCogs,
      faUserClock,
      faUserCheck,
      faUserTimes,
      faPaste);

    modal.setModalLoginHome(loginHomeFactory());
  }
}
