import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { LoginPage } from '@pages/auth/login/login';
import { CreateAccountPage } from '@pages/auth/create-account/create-account';

//services
import { AuthService } from '@services/auth-service/auth-service';

//gui
import { GUI } from '@gui/gui';
import { ModalUtil, ModalAnimation } from '@utils/modal';

//model
import { MyError } from '@model/error';

@Component({
  selector: 'page-login-home',
  templateUrl: './login-home.html',
  styleUrls: ['./login-home.scss'],
})
export class LoginHomePage {
  private pages = {
    'create-account': CreateAccountPage,
    login: LoginPage
  };

  constructor(
    private authService: AuthService,
    private gui: GUI,
    private modalUtil: ModalUtil,
    private modalCtrl: ModalController) {
  }

  onClickButton(page: string) {
    this.presentModal(this.pages[page]);
  }

  close() {
    this.modalCtrl.dismiss();
  }

  private presentModal(componentRef: any) {
    this.close();
    this.modalUtil.presentDefaultModal(componentRef, {}, ModalAnimation.Up);
  }

  async loginFacebook() {

  }

}
