import { Animation, createAnimation } from '@ionic/core';

export function transparentEnter(baseEl: HTMLElement, opts?: any): Animation {
    const baseAnimation = createAnimation();

    const wrapperAnimation = createAnimation();
    wrapperAnimation.addElement(baseEl.querySelector('.modal-wrapper'));
    wrapperAnimation
        .beforeStyles({ opacity: 1 })
        .fromTo('transform', 'translate3d(0,0,0)', 'translate3d(0,0,0)');

    return baseAnimation
        .addElement(baseEl)
        .beforeStyles({ '--ion-background-color': 'transparent' })
        .duration(0)
        .beforeAddClass('show-modal')
        .addAnimation(wrapperAnimation);
}

export function transparentLeave(baseEl: HTMLElement, opts?: any): Animation {
    const baseAnimation = createAnimation();

    return baseAnimation
        .addElement(baseEl)
        .duration(0);
}


export function iosFadeInRight(baseEl: HTMLElement, opts?: any) {
    const baseAnimation = createAnimation();

    const backdropAnimation = createAnimation();
    backdropAnimation.addElement(baseEl.querySelector('ion-backdrop'));

    backdropAnimation.fromTo('opacity', 0.01, 0.4);

    const wrapperAnimation = createAnimation();
    wrapperAnimation.addElement(baseEl.querySelector('.modal-wrapper'));

    wrapperAnimation
        .beforeStyles({ transform: 'translate3d(100%,0,0)', opacity: 1 })
        .fromTo('transform', 'translate3d(100%,0,0)', 'translate3d(0,0,0)')
        .fromTo('opacity', 1, 1);

    return baseAnimation
        .addElement(baseEl)
        .easing('cubic-bezier(0.32,0.72,0,1)')
        .duration(540)
        .beforeAddClass('show-modal')
        .addAnimation(backdropAnimation)
        .addAnimation(wrapperAnimation);
}

export function mdFadeInRight(baseEl: HTMLElement, opts?: any) {
  const baseAnimation = createAnimation();

  const backdropAnimation = createAnimation();
  backdropAnimation.addElement(baseEl.querySelector('ion-backdrop'));

  backdropAnimation.fromTo('opacity', 0.01, 0.4);

  const wrapperAnimation = createAnimation();
  wrapperAnimation.addElement(baseEl.querySelector('.modal-wrapper'));

  wrapperAnimation
      .beforeStyles({ transform: 'translate3d(100%,0,0)', opacity: 1 })
      .fromTo('transform', 'translate3d(100%,0,0)', 'translate3d(0,0,0)')
      .fromTo('opacity', 1, 1);

  return baseAnimation
      .addElement(baseEl)
      .easing('cubic-bezier(0.36,0.66,0.04,1)')
      .duration(280)
      .beforeAddClass('show-modal')
      .addAnimation(backdropAnimation)
      .addAnimation(wrapperAnimation);
}

export function iosFadeOutRight(baseEl: HTMLElement, opts?: any) {
    const baseAnimation = createAnimation();

    const backdropAnimation = createAnimation();
    backdropAnimation.addElement(baseEl.querySelector('ion-backdrop'));

    backdropAnimation.fromTo('opacity', 0.4, 0.0);

    const wrapperAnimation = createAnimation();
    wrapperAnimation.addElement(baseEl.querySelector('.modal-wrapper'));

    wrapperAnimation
        .beforeStyles({ transform: 'translate3d(0,0,0)', opacity: 1 })
        .fromTo('transform', 'translate3d(0,0,0)', 'translate3d(100%,0,0)')
        .fromTo('opacity', 1, 1);

    return baseAnimation
        .addElement(baseEl)
        .easing('cubic-bezier(0.32,0.72,0,1)')
        .duration(540)
        .addAnimation(backdropAnimation)
        .addAnimation(wrapperAnimation);
}

export function mdFadeOutRight(baseEl: HTMLElement, opts?: any) {
  const baseAnimation = createAnimation();

  const backdropAnimation = createAnimation();
  backdropAnimation.addElement(baseEl.querySelector('ion-backdrop'));

  backdropAnimation.fromTo('opacity', 0.4, 0.0);

  const wrapperAnimation = createAnimation();
  wrapperAnimation.addElement(baseEl.querySelector('.modal-wrapper'));

  wrapperAnimation
      .beforeStyles({ transform: 'translate3d(0,0,0)', opacity: 1 })
      .fromTo('transform', 'translate3d(0,0,0)', 'translate3d(100%,0,0)')
      .fromTo('opacity', 1, 1);

  return baseAnimation
      .addElement(baseEl)
      .easing('cubic-bezier(0.47,0,0.745,0.715)')
      .duration(200)
      .addAnimation(backdropAnimation)
      .addAnimation(wrapperAnimation);
}

export function scaleUpEnter(baseEl: HTMLElement, opts?: any) {
  const baseAnimation = createAnimation();

  const backdropAnimation = createAnimation();
  backdropAnimation.addElement(baseEl.querySelector('ion-backdrop'));

  backdropAnimation.fromTo('opacity', 0.01, 0.4);

  const wrapperAnimation = createAnimation();
  wrapperAnimation.addElement(baseEl.querySelector('.modal-wrapper'));

  wrapperAnimation
      .beforeStyles({ transform: 'scale3d(0.7,0.7,0.7)', opacity: 0.01 })
      .fromTo('transform', 'scale3d(0.7,0.7,0.7)', 'scale3d(1,1,1)')
      .fromTo('opacity', 0.01, 1);

  return baseAnimation
      .addElement(baseEl)
      .easing('cubic-bezier(0.68, -0.55, 0.265, 1)')
      .duration(300)
      .beforeAddClass('show-modal')
      .addAnimation(backdropAnimation)
      .addAnimation(wrapperAnimation);
}

export function scaleUpLeave(baseEl: HTMLElement, opts?: any) {
    const baseAnimation = createAnimation();

    const backdropAnimation = createAnimation();
    backdropAnimation.addElement(baseEl.querySelector('ion-backdrop'));

    backdropAnimation.fromTo('opacity', 0.4, 0.0);

    const wrapperAnimation = createAnimation();
    wrapperAnimation.addElement(baseEl.querySelector('.modal-wrapper'));

    wrapperAnimation
        .addElement(baseEl.querySelector('.modal-wrapper'))
        .beforeStyles({ transform: 'scale3d(1,1,1)', opacity: 1 })
        .fromTo('transform', 'scale3d(1,1,1)', 'scale3d(0.6,0.6,0.6)')
        .fromTo('opacity', 1, 0);

    return baseAnimation
        .addElement(baseEl)
        .easing('cubic-bezier(.1, .7, .1, 1)')
        .duration(200)
        .addAnimation(backdropAnimation)
        .addAnimation(wrapperAnimation);
}

export function noAnimationEnter(baseEl: HTMLElement, opts?: any) {
    const baseAnimation = createAnimation();

    const backdropAnimation = createAnimation();
    backdropAnimation.addElement(baseEl.querySelector('ion-backdrop'));

    backdropAnimation.fromTo('opacity', 0.01, 0.4);

    const wrapperAnimation = createAnimation();
    wrapperAnimation.addElement(baseEl.querySelector('.modal-wrapper'));

    wrapperAnimation
        .beforeStyles({ transform: 'scale3d(1,1,1)', opacity: 0 })
        .fromTo('transform', 'scale3d(1,1,1)', 'scale3d(1,1,1)')
        .fromTo('opacity', 0, 1);

    return baseAnimation
        .addElement(baseEl)
        .easing('ease-out')
        .duration(50)
        .beforeAddClass('show-modal')
        .addAnimation(backdropAnimation)
        .addAnimation(wrapperAnimation);
}

export function noAnimationLeave(baseEl: HTMLElement, opts?: any) {
    const baseAnimation = createAnimation();

    const backdropAnimation = createAnimation();
    backdropAnimation.addElement(baseEl.querySelector('ion-backdrop'));

    backdropAnimation.fromTo('opacity', 0.4, 0.0);

    const wrapperAnimation = createAnimation();
    wrapperAnimation.addElement(baseEl.querySelector('.modal-wrapper'));

    wrapperAnimation
        .beforeStyles({ opacity: 1 })
        .fromTo('opacity', 1, 0)
        .duration(0)
        .easing('linear');

    return baseAnimation
        .addElement(baseEl)
        .easing('linear')
        .duration(0)
        .addAnimation(backdropAnimation)
        .addAnimation(wrapperAnimation);
}