import { NgModule } from '@angular/core';
import { Location, PlatformLocation, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { SvgFixingDirective } from '@directives/svg-fixing/svg-fixing';

export function getBaseHref(platformLocation: PlatformLocation): string {
    return platformLocation.getBaseHrefFromDOM();
}

const CUSTOM_DIRECTIVES = [
    SvgFixingDirective
];

@NgModule({
    declarations: [CUSTOM_DIRECTIVES],
    imports: [],
    exports: [CUSTOM_DIRECTIVES],
    providers: [
        Location,
        { provide: LocationStrategy, useClass: PathLocationStrategy }
    ]
})
export class FixDirectivesModule { }
