import {
  LoadingController,
  AlertController,
  ToastController,
  //Toast,
  ActionSheetController,
  //ActionSheet
} from '@ionic/angular';

import { Injectable } from '@angular/core';

// utils
import { isPresent } from '@utils/micro-lodash';

//model
import { MyError } from '@model/error';


@Injectable({
  providedIn: 'root'
})
export class GUI {

  constructor(
    private loadingCtrl: LoadingController,
    private alertCtrl: AlertController,
    private toastCtrl: ToastController,
    private actionSheetCtrl: ActionSheetController) {
  }

  showAlertError(msg: string, callback?: () => void) {
    this.showAlertMessage('Error', msg, callback);
  }

  async showAlertSingleMessage(msg: string, callback?: () => void) {
    const alert = await this.alertCtrl.create({
      message: msg,
      backdropDismiss: false,
      buttons: [
        {
          text: 'Aceptar',
          handler: () => {
            if (callback) {
              callback();
            }
          }
        }]
    });

    await alert.present();
  }

  async showAlertMessage(header: string, subHeader: string, callback?: () => void) {
    const alert = await this.alertCtrl.create({
      header: header,
      subHeader: subHeader,
      backdropDismiss: false,
      buttons: [
        {
          text: 'Aceptar',
          handler: () => {
            if (callback) {
              callback();
            }
          }
        }]
    });

    await alert.present();
  }

  async showConfirmMessage(
    header: string,
    message: string,
    okCallback?: () => any,
    cancelCallback?: () => any,
  ) {
    const confirm = await this.alertCtrl.create({
      header: header,
      message: message,
      buttons: [
        {
          text: 'Cancelar',
          handler: () => {
            if (cancelCallback) {
              cancelCallback();
            }
          }
        },
        {
          text: 'OK',
          handler: () => {
            if (okCallback) {
              okCallback();
            }
          }
        }
      ]
    });

    await confirm.present();
  }

  async presentPrompt(
    header: string,
    message: string = '',
    okCallback?: (data: any) => void,
    cancelCallback?: (data: any) => void) {

    const prompt = await this.alertCtrl.create({
      header: header,
      message: message,
      inputs: [
        {
          name: 'data',
          placeholder: message
        },
      ],
      buttons: [
        {
          text: 'Cancelar',
          handler: (result) => {
            if (cancelCallback) {
              cancelCallback(result.data);
            }
          }
        },
        {
          text: 'OK',
          handler: (result) => {
            if (okCallback) {
              okCallback(result.data);
            }
          }
        }
      ]
    });

    await prompt.present();
  }

  async presentLoading(message?: string) {
    const loading = await this.loadingCtrl.create({
      message: isPresent(message) ? message : ''
    });

    await loading.present();
    return loading;
  }

  async presentBarsLoading(message?: string) {
    const _message = isPresent(message) ? message : '';
    const loading = await this.loadingCtrl.create({
      cssClass: 'custom-spinner-wheel',
      translucent: true,
      spinner: null,
      message: `
            <div class="custom-spinner-container">
                <div class="rect1"></div>
                <div class="rect2"></div>
                <div class="rect3"></div>
                <div class="rect4"></div>
                <div class="rect5"></div>
                </div><span>${_message}</span>
            </div>`
    });

    await loading.present();
    return loading;
  }

  async presentBarsLoadingAndWait(func: () => Promise<any>, resolve?: () => void, reject?: () => void) {
    const loading = await this.presentBarsLoading();

    func().then(_ => {
      loading.dismiss().then(resolve);
    })
      .catch((error: MyError) => {
        loading.dismiss().then(() => {
          this.showAlertError(error.message, reject);
        });
      });
  }

  async presentCircleLoadingAndWait(func: () => Promise<any>, resolve?: (result?: any) => void, reject?: () => void) {
    const loading = await this.presentCircleLoading();
    try {
      const result = await func();
      loading.dismiss().then(() => {
        if (resolve) {
          resolve(result);
        }
      });
    } catch (error) {
      loading.dismiss().then(() => {
        this.showAlertError(error.message, reject);
      });
    }
  }

  async presentCircleLoading() {
    const loading = await this.loadingCtrl.create({
      cssClass: 'custom-spinner-circle',
      translucent: true,
      spinner: null,
      message: `
            <div class="cssload-container">
              <div class="cssload-loading">
                <div class="cssload-ani1"><i></i><i></i><i></i></div>
                <div class="cssload-ani2"><i></i><i></i><i></i></div>
                <div class="cssload-ani3"><i></i><i></i><i></i></div>
                <div class="cssload-ani4"><i></i><i></i><i></i></div>
              </div>
            </div>`
    });

    await loading.present();
    return loading;
  }

  async presentSpinner(duration: number): Promise<any> {
    const loading = await this.loadingCtrl.create({
      message: '',
      duration: duration
    });

    await loading.present();
    return loading;
  }

  async presentErrorToast(msg: string, duration?: number, ok: boolean = true, position?: string) {
    return this.presentToast(msg, duration, ok, position, 'cssErrorToast');
  }

  async presentToast(msg: string, duration?: number, ok: boolean = true, position?: string, cssClass?: string) {
    const opt: any = {
      message: msg,
      position: position ? position : 'top',
    };

    if (duration) {
      opt.duration = duration;
    }

    if (cssClass) {
      opt.cssClass = cssClass;
    }

    if (ok) {
      opt.buttons = [{ text: ok ? 'OK' : '' }];
    }

    const toast = await this.toastCtrl.create(opt);

    toast.present();
    return toast.onDidDismiss();
  }

  async presentActionSheet(buttons: any[], header: string = '') {
    const actionSheet = await this.actionSheetCtrl.create({
      header: header,
      buttons: buttons
    });

    await actionSheet.present();
    return actionSheet;
  }

}
