import { ModalController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ResetPasswordPage } from '@pages/auth/reset-password/reset-password';

//services
import { AuthService } from '@services/auth-service/auth-service';

//validators
import { CustomValidators } from '@validators/custom-validators';

//gui
import { GUI } from '@gui/gui';

import { ModalUtil, ModalAnimation } from '@utils/modal';

//model
import { Account } from '@model/account';
import { MyError } from '@model/error';

@Component({
  selector: 'page-login',
  templateUrl: './login.html',
  styleUrls: ['./login.scss'],
})
export class LoginPage implements OnInit {
  loginForm: FormGroup;
  account: Account = new Account();

  constructor(
    private modalUtil: ModalUtil,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private modalCtrl: ModalController,
    private gui: GUI) {
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.maxLength(50), CustomValidators.validateEmail(true)]],
      password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(30)]]
    });
  }

  close() {
    this.modalCtrl.dismiss();
  }

  forgetPassword() {
    const accountNameControl = this.loginForm.controls['email'];
    let email = '';
    if (accountNameControl.valid) {
      email = this.account.email;
    }

    this.presentModal(ResetPasswordPage, { email });
  }

  async login() {
    const loading = await this.gui.presentCircleLoading();

    this.account.email = this.account.email.trim();
    this.authService.login(this.account).then(user => {
      loading.dismiss().then(() => {
        this.close();
      });
    }).catch((error: MyError) => {
      loading.dismiss().then(() => {
        this.gui.showAlertError(error.message);
      });
    });
  }

  private presentModal(componentRef: any, params: any) {
    this.close();
    this.modalUtil.presentDefaultModal(componentRef, params, ModalAnimation.Up);
  }
}
