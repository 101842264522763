export function isPresent(elem: any): boolean {
    return elem !== null && elem !== undefined;
}

export function isEmpty(elem: string | any[]): boolean {
    return elem === null || elem === undefined || elem.length === 0;
}

export function isString(elem): elem is string {
    return typeof elem === 'string';
}

export function isBoolean(elem): elem is boolean {
    return typeof elem === 'boolean';
}

export function boolValue(value): boolean {
    if (isBoolean(value)) {
        return value;
    }

    if (isString(value)) {
        if (value === 'true') {
            return true;
        }

        if (value === 'false') {
            return false;
        }
    }

    return !!value;
}

export function uniqueArray<T>(array: T[], id: string): T[] {
    const seen = new Set();
    const filteredArr = array.filter(el => {
      const duplicate = seen.has(el[id]);
      seen.add(el[id]);
      return !duplicate;
    });

    return filteredArr;
}

export function navigateToValue(obj: object, fields: string[]) {
    let ref: any = obj;

    for (const field of fields) {
        ref = ref[field];
        if (ref === undefined || ref === null) {
            return;
        }
    }

    return ref;
}

export function arrayEquals(a1: any[], a2: any[]): boolean {
    return isPresent(a1) && isPresent(a2) && a1.length === a2.length && a1.every((v, i) => v === a2[i]);
}

export function addDecimalSeparator(value: string): string {
    const rgx = /(\d+)(\d{3})/;
    while (rgx.test(value)) {
        value = value.replace(rgx, '$1' + '.' + '$2');
    }

    return value;
}

export function pxToNumber(value: string): number {
    const l = value.length;
    return l > 2 ? parseInt(value.substr(0, l - 2), 10) : 0;
}

export function alignTo(n: number, align: number) {
    return ('0'.repeat(align) + n).slice(-align);
}

export function delay(n): Promise<number> {
    return new Promise((resolve, reject) => {
        setTimeout(resolve, n);
    });
}

export function propertiesToCount(obj): { [key: string]: number } {
    const result = {};

    for (const property in obj) {
        if (obj.hasOwnProperty(property)) {
            result[property] = Object.keys(obj[property]).length;
        }
    }

    return result;
}

export function group<T>(array: T[], predicate: (curr: T, prev: T) => boolean, orderGroup?: (elem1: T[], elem2: T[]) => number, orderItems?: (elem1: T, elem2: T) => number): T[][] {
    let _group: T[];
    let result: T[][] = [];
    let prev: T = null;

    array.forEach(m => {
        const curr = m;
        if (!prev || predicate(curr, prev)) {
            _group = [];
            result.push(_group);
        }

        _group.push(curr);
        prev = curr;
    });

    if (orderGroup) {
        result = result.sort(orderGroup);
    }

    if (orderItems) {
        for (let i = 0; i < result.length; i++) {
            result[i] = result[i].sort(orderItems);
        }
    }

    return result;
}

export function propertiesToList(obj): string[] {
    const result: string[] = [];

    for (const property in obj) {
        if (obj.hasOwnProperty(property)) {
            result.push(property);
        }
    }

    return result;
}

//http://blog.soulserv.net/understanding-object-cloning-in-javascript-part-ii/
export function cloneDeep(originalObject: any, circular: boolean = false) {
    // First create an empty object with
    // same prototype of our original source

    let propertyIndex,
        descriptor,
        keys,
        current,
        nextSource,
        indexOf;

    const copies = [{
        source: originalObject,
        target: Object.create(Object.getPrototypeOf(originalObject))
    }],
        cloneObject = copies[0].target,
        sourceReferences = [originalObject],
        targetReferences = [cloneObject];

    // First in, first out
    while (current = copies.shift()) {
        keys = Object.getOwnPropertyNames(current.source);

        for (propertyIndex = 0; propertyIndex < keys.length; propertyIndex++) {
            // Save the source's descriptor
            descriptor = Object.getOwnPropertyDescriptor(current.source, keys[propertyIndex]);

            if (!descriptor.value || typeof descriptor.value !== 'object') {
                Object.defineProperty(current.target, keys[propertyIndex], descriptor);
                continue;
            }

            nextSource = descriptor.value;
            descriptor.value = Array.isArray(nextSource) ?
                [] :
                Object.create(Object.getPrototypeOf(nextSource));

            if (circular) {
                indexOf = sourceReferences.indexOf(nextSource);

                if (indexOf !== -1) {
                    // The source is already referenced, just assign reference
                    descriptor.value = targetReferences[indexOf];
                    Object.defineProperty(current.target, keys[propertyIndex], descriptor);
                    continue;
                }

                sourceReferences.push(nextSource);
                targetReferences.push(descriptor.value);
            }

            Object.defineProperty(current.target, keys[propertyIndex], descriptor);

            copies.push({ source: nextSource, target: descriptor.value });
        }
    }

    return cloneObject;
}

