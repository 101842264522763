import { Injectable } from '@angular/core';

//firestore
import * as firebase from 'firebase/app';

import {
  AngularFirestore,
  AngularFirestoreDocument,
  AngularFirestoreCollection,
  DocumentChangeAction
} from '@angular/fire/firestore';

//FirestoreCursor
import { FirestoreCursor } from './firestore-cursor';

//model
import { Storable } from '@model/storable';


//Observable
import { Observable } from 'rxjs';
import { take, tap, first, map } from 'rxjs/operators';

export enum E_Operation { Add = 'add', Delete = 'delete', Update = 'update' }
export interface Operation {
  type?: E_Operation;
  ref: CollectionPredicate<any> | DocPredicate<any>;
  payload?: Storable | any;
  addTimestamp?: boolean;
}

type CollectionPredicate<T> = string | AngularFirestoreCollection<T>;
type DocPredicate<T> = string | firebase.firestore.DocumentReference | AngularFirestoreDocument<T>;

@Injectable({
  providedIn: 'root'
})
export class FirestoreService {

  constructor(private afs: AngularFirestore) {

  }

  col<T>(ref: CollectionPredicate<T>, queryFn?): AngularFirestoreCollection<T> {
    return typeof ref === 'string' ? this.afs.collection<T>(ref, queryFn) : ref;
  }

  col$<T>(ref: CollectionPredicate<T>, queryFn?): Observable<T[]> {
    return this.col(ref, queryFn).snapshotChanges().pipe(
      map(docs => {
        return docs.map(a => a.payload.doc.data()) as T[];
      })
    );
  }

  doc<T>(ref: DocPredicate<T>): AngularFirestoreDocument<T> {
    if (typeof ref === 'string') {
      return this.afs.doc<T>(ref);
    } else if (ref instanceof AngularFirestoreDocument) {
      return ref;
    } else {
      return this.afs.doc<T>((ref as any).path);
    }
  }

  // Return an Observable
  doc$<T>(ref: DocPredicate<T>): Observable<T> {
    return this.doc(ref).snapshotChanges().pipe(
      map(doc => {
        return doc.payload.data() as T;
      })
    );
  }

  // Return an Promise
  docOnce<T>(ref: DocPredicate<T>): Promise<T> {
    //return this.doc<T>(ref).valueChanges().pipe(first()).toPromise();

    const _ref = this.doc<T>(ref).ref;
    return _ref.get({ source: 'server' }).then((ds: firebase.firestore.DocumentSnapshot) => {
      return ds.exists ? ds.data() as T : null;
    });
  }

  docWithId<T>(ref: DocPredicate<T>): Promise<T> {
    return this.docWithId$<T>(ref).pipe(first()).toPromise();
  }

  docWithId$<T>(ref: DocPredicate<T>): Observable<T> {
    return this.doc(ref).snapshotChanges().pipe(
      map(doc => {
        return {
          id: doc.payload.id,
          ...doc.payload.data() as any
        } as any;
      })
    );
  }

  /// returns a documents references mapped to AngularFirestoreDocument
  docWithRefs$<T>(ref: DocPredicate<T>): Observable<T> {
    return this.doc$(ref).pipe(
      map(doc => {
        for (const k of Object.keys(doc)) {
          if (doc[k] instanceof firebase.firestore.DocumentReference) {
            doc[k] = this.doc(doc[k].path);
          }
        }
        return doc;
      })
    );
  }

  /// **************
  /// Create and read doc references
  /// **************
  /// create a reference between two documents
  connect(host: DocPredicate<any>, key: string, doc: DocPredicate<any>) {
    return this.doc(host).update({ [key]: this.doc(doc).ref });
  }

  colWithIds$<T>(ref: CollectionPredicate<T>, queryFn?, cursor?: FirestoreCursor): Observable<T[]> {
    const queryFnCursor = this.getQueryFromCursor(queryFn, cursor);

    return this.col(ref, queryFnCursor).snapshotChanges().pipe(
      map(actions => {
        this.updateCursor(actions, cursor);

        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data as any };
        });
      })
    );
  }

  colWithIds<T>(ref: CollectionPredicate<T>, queryFn?): Promise<T[]> {
    return this.colWithIds$(ref, queryFn).pipe(first()).toPromise();
  }

  colOnce<T>(ref: CollectionPredicate<T>, queryFn?): Promise<T[]> {
    /*
      Antes lo hacía de esta manera pero no funciona bien con enablePersistence,
      ya que cuando me devuelve el primer valor desde el cache y antes de actualizar el cache desde
      el server se cierra el observer y por lo tanto siempre se retorna el valor desde el cache.
    */

    //return this.col<T>(ref, queryFn).valueChanges().pipe(first()).toPromise();

    let _ref = this.col<T>(ref, queryFn).ref;

    if (queryFn) {
      _ref = queryFn(_ref);
    }

    return _ref.get({ source: 'server' }).then((qs: firebase.firestore.QuerySnapshot) => {
      return qs.docs.map((qds: firebase.firestore.QueryDocumentSnapshot) => qds.data() as T);
    });
  }

  update<T>(ref: DocPredicate<T>, data: Storable | any, addTimestamp: boolean = false): Promise<void> {

    if (this.isStorable(data)) {
      data = data.getStorable(true);
    }

    const toUpdate = {
      ...data
    };

    if (addTimestamp) {
      toUpdate.updatedAt = this.timestamp;
    }

    return this.doc(ref).update(toUpdate);
  }

  merge<T>(ref: DocPredicate<T>, data: Storable | any, addTimestamp: boolean = false): Promise<void> {

    if (this.isStorable(data)) {
      data = data.getStorable(true);
    }

    const toUpdate = {
      ...data
    };

    if (addTimestamp) {
      toUpdate.updatedAt = this.timestamp;
    }

    return this.doc(ref).set(toUpdate, { merge: true });
  }

  set<T>(ref: DocPredicate<T>, data: Storable | any, addTimestamp: boolean = false): Promise<void> {
    if (this.isStorable(data)) {
      data = data.getStorable();
    }

    const toSet = {
      ...data
    };

    if (addTimestamp) {
      const timestamp = this.timestamp;
      toSet.updatedAt = timestamp;
      toSet.createdAt = timestamp;
    }

    return this.doc(ref).set(toSet);
  }

  add<T>(ref: CollectionPredicate<T>, data: Storable | any, addTimestamp: boolean = false): Promise<firebase.firestore.DocumentReference> {
    const isStorable = this.isStorable(data);

    let data$;
    if (isStorable) {
      data$ = data.getStorable();
    } else {
      data$ = data;
    }

    const toAdd = {
      ...data$
    };

    if (addTimestamp) {
      const timestamp = this.timestamp;
      toAdd.updatedAt = timestamp;
      toAdd.createdAt = timestamp;
    }

    return this.col(ref).add(toAdd).then(_ref => {
      if (isStorable) {
        data.id = _ref.id;
      }

      return _ref;
    });
  }

  batch(operations: Operation[]): Promise<void> {
    const batch: firebase.firestore.WriteBatch = this.afs.firestore.batch();

    operations.forEach(operation => {
      const payload = operation.payload;
      const isStorable = this.isStorable(payload);

      const type = operation.type;
      let id: string;
      let docRef;

      if (isStorable && payload.id) {
        id = payload.id;
      }

      if (!id && (!type || type === E_Operation.Add)) {
        id = this.afs.createId();
      }

      if (id) {
        docRef = this.col(operation.ref as CollectionPredicate<any>).doc(id);
      } else {
        docRef = this.doc(operation.ref as DocPredicate<any>);
      }

      let data$;
      if (isStorable) {
        data$ = payload.getStorable();
        payload.id = id;
      } else {
        data$ = payload || {};
      }

      const toDatabase = {
        ...data$
      };

      if (operation.addTimestamp) {
        const timestamp = this.timestamp;
        toDatabase.updatedAt = timestamp;

        if ((!type || type === E_Operation.Add)) {
          toDatabase.createdAt = timestamp;
        }
      }

      if (!type) {
        batch.set(docRef.ref, toDatabase);
      } else {
        switch (type) {
          case E_Operation.Add:
            batch.set(docRef.ref, toDatabase);
            break;
          case E_Operation.Delete:
            batch.delete(docRef.ref);
            break;
          case E_Operation.Update:
            batch.update(docRef.ref, toDatabase);
            break;
        }
      }

    });

    return batch.commit();
  }

  addAll<T>(ref: CollectionPredicate<T>, storables: (Storable | any)[], addTimestamp: boolean = false): Promise<void> {
    const batch: firebase.firestore.WriteBatch = this.afs.firestore.batch();

    storables.forEach(data => {
      const isStorable = this.isStorable(data);
      const id = isStorable && data.id ? data.id : this.afs.createId();
      const docRef = this.col(ref).doc(id);

      let data$;
      if (isStorable) {
        data$ = data.getStorable();
        data.id = id;
      } else {
        data$ = data;
      }

      const toAdd = {
        ...data$
      };

      if (addTimestamp) {
        const timestamp = this.timestamp;
        toAdd.updatedAt = timestamp;
        toAdd.createdAt = timestamp;
      }

      batch.set(docRef.ref, toAdd);
    });

    return batch.commit();
  }

  // *** Usage
  // this.db.upsert('notes/xyz', { content: 'hello dude'})
  // *** Code
  upsert<T>(ref: DocPredicate<T>, storable: Storable, addTimestamp: boolean = false): Promise<void> {
    const doc = this.doc(ref).snapshotChanges().pipe(
      take(1)
    ).toPromise();

    return doc.then(snap => {
      return snap.payload.exists ? this.update(ref, storable, addTimestamp) : this.set(ref, storable, addTimestamp);
    });
  }

  createId(): string {
    return this.afs.createId();
  }

  inspectDoc(ref: DocPredicate<any>): void {
    const tick = new Date().getTime();
    this.doc(ref).snapshotChanges().pipe(
      take(1),
      tap(d => {
        const tock = new Date().getTime() - tick;
        console.log(`Loaded Document in ${tock}ms`, d);
      }))
      .subscribe();
  }

  inspectCol(ref: CollectionPredicate<any>): void {
    const tick = new Date().getTime();
    this.col(ref).snapshotChanges().pipe(
      take(1),
      tap(c => {
        const tock = new Date().getTime() - tick;
        console.log(`Loaded Collection in ${tock}ms`, c);
      }))
      .subscribe();
  }

  toGeopoint(latlng: { lat: number, lng: number }): firebase.firestore.GeoPoint {
    return new firebase.firestore.GeoPoint(latlng.lat, latlng.lng);
  }

  toGeopoints(latlngs: { lat: number, lng: number }[]): firebase.firestore.GeoPoint[] {
    return latlngs && latlngs.map(latlng => this.toGeopoint(latlng));
  }

  fromGeopoint(geopoint: firebase.firestore.GeoPoint) {
    return {
      lat: geopoint.latitude,
      lng: geopoint.longitude
    };
  }

  //privates
  private getQueryFromCursor(queryFn, cursor: FirestoreCursor) {
    if (queryFn && cursor) {
      return (ref) => cursor.query(queryFn(ref));
    }

    return queryFn;
  }

  private updateCursor(updater, cursor) {
    if (cursor) {
      cursor.update(updater);
    }
  }

  //Utils
  public get timestamp(): any {
    return firebase.firestore.FieldValue.serverTimestamp();
  }

  private isStorable(data): boolean {
    return !!data && typeof data.getStorable === 'function';
  }
}
