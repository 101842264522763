import { Injectable } from '@angular/core';
import { ModalOptions, ComponentProps, ComponentRef, OverlayEventDetail } from '@ionic/core';
import { ModalController, Config } from '@ionic/angular';

//import { loginHomeFactory } from '@pages/auth/login-home-factory';

//gui
import { GUI } from '@gui/gui';

// animations
import * as CustomAnimation from '@transitions/animations';

export enum ModalAnimation { No, Default, Up, FadeRight }

const MY_CSS_MODAL = 'my-class-modal';
export const MY_CSS_MODAL_WIDTH = 'my-class-modal-width';
const MY_MODAL_TRANSPARENT = 'my-modal-transparent';

@Injectable({
  providedIn: 'root'
})
export class ModalUtil {
  private loginHome: any;

  constructor(
    private gui: GUI,
    private config: Config,
    private modalController: ModalController) {
  }

  setModalLoginHome(page: any) {
    this.loginHome = page;
  }

  public presentModalLoginHome(modalCtrl?: ModalController, present?: (modalElem: HTMLIonModalElement) => void): Promise<void> {
    return this.presentDefaultModal(this.loginHome, {}, ModalAnimation.Up, modalCtrl, MY_CSS_MODAL, present);
  }

  // public presentModalLoginHome(): Promise<void> {
  //   return new Promise((resolve, reject) => {
  //     this.gui.showAlertSingleMessage(`Enviá HOLA a Whatsapp para acceder a tu cuenta`, () => {
  //       resolve();
  //     });
  //   });
  // }

  /*
  ModalOptions {
      component: ComponentRef;
      componentProps?: ComponentProps;
      showBackdrop?: boolean;
      backdropDismiss?: boolean;
      enterAnimation?: AnimationBuilder;
      leaveAnimation?: AnimationBuilder;
      cssClass?: string | string[];
      delegate?: FrameworkDelegate;
  }
  */

  public presentDefaultModal(name: ComponentRef, params?: ComponentProps, animation: ModalAnimation = ModalAnimation.Default, modalCtrl?: ModalController, cssClass?: string, present?: (modalElem: HTMLIonModalElement) => void): Promise<any> {
    const modalController = modalCtrl || this.modalController;

    return new Promise((resolve, reject) => {
      const modalOptions: ModalOptions = {
        component: name,
        componentProps: params,
        showBackdrop: true,
        backdropDismiss: false,
        cssClass: cssClass ? cssClass : MY_CSS_MODAL
      };

      switch (animation) {
        case ModalAnimation.No:
          modalOptions.enterAnimation = CustomAnimation.noAnimationEnter;
          modalOptions.leaveAnimation = CustomAnimation.noAnimationLeave;
          break;
        case ModalAnimation.Up:
          modalOptions.enterAnimation = CustomAnimation.scaleUpEnter;
          modalOptions.leaveAnimation = CustomAnimation.scaleUpLeave;
          break;
        case ModalAnimation.FadeRight:
          const ios = this.isIOS();
          modalOptions.enterAnimation = ios ? CustomAnimation.iosFadeInRight : CustomAnimation.mdFadeInRight;
          modalOptions.leaveAnimation = ios ? CustomAnimation.iosFadeOutRight : CustomAnimation.mdFadeOutRight;
          break;
        default:
          break;
      }

      modalController.create(modalOptions).then((modal: HTMLIonModalElement) => {
        modal.onDidDismiss().then((oed: OverlayEventDetail) => {
          resolve(oed.data);
        });

        modal.present().then(() => {
          if (present) {
            present(modal);
          }
        });
      });
    });
  }

  public presentViewerModal(name: ComponentRef, img: HTMLElement, modalCtrl?: ModalController, present?: (modalElem: HTMLIonModalElement) => void): Promise<any> {
    const modalController = modalCtrl || this.modalController;

    return new Promise((resolve, reject) => {
      const modalOptions: ModalOptions = {
        component: name,
        componentProps: { img },
        showBackdrop: true,
        backdropDismiss: false,
        cssClass: MY_MODAL_TRANSPARENT,
        enterAnimation: CustomAnimation.transparentEnter,
        leaveAnimation: CustomAnimation.transparentLeave
      };

      modalController.create(modalOptions).then((modal: HTMLIonModalElement) => {
        modal.onDidDismiss().then((oed: OverlayEventDetail) => {
          resolve(oed.data);
        });

        modal.present().then(() => {
          if (present) {
            present(modal);
          }
        });
      });
    });
  }

  private isIOS(): boolean {
    return this.config.get('mode') === 'ios';
  }

}
