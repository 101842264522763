import { FormControl, Validators, ValidatorFn } from '@angular/forms';

export class CustomValidators {
  private static EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)+$/i;

  static validateEmail(trim: boolean = false) {
    return (c: FormControl) => CustomValidators.EMAIL_REGEXP.test(trim && c.value ? c.value.trim() : c.value) ? null : {
      validateEmail: {
        valid: false
      }
    };
  }

  static validateTrimString() {
    return Validators.pattern(/^((\S.*\S)|\S|\S\S)$/);
  }

  static validateEmailString(email: string): boolean {
    return CustomValidators.EMAIL_REGEXP.test(email);
  }

  static validateEqualTo(formControlName: string) {
    return (c: FormControl) => {
      // self value (e.g. retype password)
      const v = c.value;
      // control value (e.g. password)
      const e = c.parent ? c.parent.controls[formControlName] : undefined;
      // value not equal
      if (e && v !== e.value) {
        return {
          validateEqual: false
        };
      }

      return null;
    };
  }

  static validateApply(applyValidator: boolean): ValidatorFn {
    return (c: FormControl) => applyValidator ? null : ({ validateApply: { valid: false } });
  }

}
