import { Injectable } from '@angular/core';

//firebase
import * as firebase from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';

//Observables
import { Observable, of, from } from 'rxjs';
import { first, map, distinctUntilChanged, switchMap } from 'rxjs/operators';

//model
import { Account } from '@model/account';
import { User } from '@model/user';
import { IRoles } from '@model/roles';
import { MyError, handleError, ErrorType } from '@model/error';

//providers
import { FirestoreService } from '@services/firestore-service/firestore-service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _isLoggedIn: boolean = undefined;
  private _claims: any;

  constructor(
    private afAuth: AngularFireAuth,
    private fsService: FirestoreService) {
    this.subscribeIsLoggedIn();
  }

  createAccount(user: User): Promise<User> {
    return this.afAuth.createUserWithEmailAndPassword(user.account.email, user.account.password)
      .then(userCredential => {
        const fbuser = userCredential.user;

        //limpiamos el password por seguiridad
        user.account.password = '';
        user.id = fbuser.uid;

        return Promise.all([
          fbuser.updateProfile({
            displayName: user.displayName
            //photoURL: "https://example.com/jane-q-user/profile.jpg"
          }),
          this.createUser(user)
        ]).then(results => {
          return results[1];
        }).catch(handleError());
      })
      .catch(handleError());
  }

  login(account: Account): Promise<User> {
    return this.processUserCredential(
      this.afAuth.signInWithEmailAndPassword(account.email, account.password),
      true
    );
  }

  async loginCustomToken(token: string): Promise<User> {
    const user = await this.processUserCredential(
      this.afAuth.signInWithCustomToken(token),
      true
    );

    return user;
  }

  resetPassword(email: string): Promise<any> {
    return this.afAuth.sendPasswordResetEmail(email)
      .catch(handleError(ErrorType.ResetPassword));
  }

  sendEmailVerification(): Promise<any> {
    return this.authState().pipe(
      first()
    ).toPromise().then(fbuser => {
      if (fbuser) {
        return fbuser.sendEmailVerification().catch(handleError());
      }

      return;
    });
  }

  isLoggedIn(): Promise<boolean> {
    if (this._isLoggedIn === undefined) {
      return new Promise((resolve, reject) => {
        this.afAuth.authState.pipe(first()).subscribe(res => {
          const _isLoggedIn = res && res.uid ? true : false;
          return resolve(_isLoggedIn);
        }, error => {
          resolve(false);
        });
      });
    } else {
      return Promise.resolve(this._isLoggedIn);
    }
  }

  async isLoggedInWithRole(roles: IRoles): Promise<boolean> {
    let isLoggedIn = await this.isLoggedIn();
    if (isLoggedIn) {
      const user = await this.getUserLoggedIn(true);
      isLoggedIn = user && user.roles.some(roles);
    }

    return isLoggedIn;
  }

  isLoggedInWithRole$(roles: IRoles): Observable<boolean> {
    return this.getUserLoggedIn$(true).pipe(
      map(user => user ? user.roles.some(roles) : false),
      distinctUntilChanged()
    );
  }

  getUserLoggedIn$(fullInfo: boolean = false): Observable<User> {
    if (fullInfo) {
      return this.authState().pipe(
        switchMap(user => user && user.uid ? this.fsService.docWithId$(`users/${user.uid}`) : of<User>(null)),
        map(user => user ? new User(user) : null)
      );
    }

    return this.authState().pipe(
      map(user => user && user.uid ? new User(user) : null)
    );
  }

  getUserLoggedIn(fullInfo: boolean = false): Promise<User> {
    return this.getUserLoggedIn$(fullInfo).pipe(first()).toPromise()
      .catch(handleError());
  }

  getUserLoggedInReload$(): Observable<User> {
    return this.authState().pipe(
      switchMap(fbuser => {
        if (fbuser && fbuser.uid) {
          return from(
            fbuser.reload().then(() => {
              return fbuser;
            })
          );
        } else {
          return of(null);
        }
      }),
      map(fbuser => fbuser && fbuser.uid ? new User(fbuser) : null)
    );
  }

  getUserLoggedInReload(): Promise<User> {
    return this.getUserLoggedInReload$().pipe(first()).toPromise()
      .catch(handleError());
  }

  signOut(): Promise<any> {
    return Promise.all([
      this.afAuth.signOut()
      //this.facebookProvider.logout()
    ]).catch(_ => {
      return;
    });
  }

  authState(): Observable<firebase.User | null> {
    return this.afAuth.authState;
  }



  getUser$(uid: string): Observable<User> {
    return this.fsService.docWithId$(`users/${uid}`).pipe(
      map(user => new User(user))
    );
  }

  getClaims(): any {
    return this._claims;
  }

  private processUserCredential(promiseUserCredential: Promise<firebase.auth.UserCredential>, throwError: boolean): Promise<User> {
    return promiseUserCredential.then(userCredential => {
      const fbuser = userCredential.user;
      return this.getUserOnce(fbuser.uid).then(user => {
        if (user && user.roles.client) {
          return user;
        } else {
          this.signOut();
          throw new MyError({ code: 'auth/user-not-found' });
        }
      });
    })
      .catch(err => {
        return this.signOut().then(() => {
          if (throwError) {
            throw err;
          } else {
            return err;
          }
        });
      })
      .catch(handleError(ErrorType.Login));
  }

  private getUserOnce(uid: string): Promise<User> {
    return this.fsService.docWithId$<User>(`users/${uid}`).pipe(first())
      .toPromise()
      .then(user => new User(user));
  }

  private async createUser(user: User): Promise<User> {
    //await this.addInfoUtil(user);
    await this.fsService.set(`users/${user.id}`, user, true);

    return user;
  }

  private async upsertUser(user: User): Promise<User> {
    //await this.addInfoUtil(user);
    return this.fsService.upsert(`users/${user.id}`, user, true).then(() => user);
  }

  private subscribeIsLoggedIn() {
    this.afAuth.authState.subscribe(res => {
      this._isLoggedIn = res && res.uid ? true : false;
    }, err => {
    }, () => {
    });
  }
}
