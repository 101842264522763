export enum ErrorType { ResetPassword, Login, Callable, Generic }

//Observables
import { throwError, Observable } from 'rxjs';

export class MyError {
    message: string = undefined;
    code: string = undefined;

    constructor(error, errorType: ErrorType = ErrorType.Generic) {
        if (typeof error === 'string') {
            this.message = error;
        } else if (error instanceof MyError) {
            this.code = error.code;
            this.message = error.message;
        } else {
            this.code = error.code;
            switch (error.code) {
                case 'auth/network-request-failed':
                    this.message = 'No se ha detectado una conexión de red, revise su conexión e intente nuevamente';
                    break;
                case 'auth/weak-password':
                    this.message = 'La contraseña es muy débil';
                    break;
                case 'auth/wrong-password':
                    //se coloca esta mensaje generico para que no quede en evidencia que el usuario no existe
                    //cuando se intenta colocar usuarios y contraseñas al azar.
                    this.message = 'Usuario o contraseña incorrectos';
                    break;
                case 'auth/invalid-email':
                    this.message = 'Email inválido';
                    break;
                case 'auth/user-disabled':
                    this.message = 'Email inhabilitado';
                    break;
                case 'auth/invalid-custom-token':
                    this.message = 'Sesión expirada, para ingresar a su cuenta solicite un nuevo enlace enviando HOLA desde Whatsapp';
                    break;
                case 'auth/user-not-found':
                    //this._message = "Email no exitente";
                    //se coloca esta mensaje generico para que no quede en evidencia que el usuario no existe
                    //cuando se intenta colocar usuarios y contraseñas al azar.
                    switch (errorType) {
                        case ErrorType.ResetPassword:
                            this.message = 'Email no encontrado';
                            break;
                        case ErrorType.Login:
                        default:
                            this.message = 'Usuario o contraseña incorrectos';
                            break;
                    }
                    break;
                case 'auth/email-already-exists':
                    this.message = 'El email está siendo utilizado por otra cuenta';
                    break;
                case 'auth/email-already-in-use':
                    this.message = 'Usuario ya registrado';
                    break;
                case 'auth/account-exists-with-different-credential':
                    this.message = 'El email está siendo utilizado por otra cuenta';
                    break;
                case 'permission-denied':
                    if (errorType !== ErrorType.Generic && error.message) {
                        this.message = error.message
                    } else {
                        this.message = 'Permisos insuficientes';
                    }

                    break;
                case 'auth/requires-recent-login':
                    this.message = 'Requiere iniciar sesión nuevamente';
                    break;
                case 'LOGIN_FACEBOOK_ERROR':
                    this.message = 'Error al iniciar sesión';
                    break;
                case 'LOGIN_FACEBOOK_USER_CANCELLED':
                    this.message = 'Cancelado por el usuario';
                    break;
                default:
                    this.message = error.message ? error.message : 'error sin mensaje';
                    break;
            }
        }
    }

    isRequiresRecentLogin(): boolean {
        return this.code === 'auth/requires-recent-login';
    }
}

export function handleError(errorType: ErrorType = ErrorType.Generic) {
    return (error) => {
        throw new MyError(error, errorType);
    };
}

export function handleError$(errorType: ErrorType = ErrorType.Generic) {
    return error => {
        return throwError(new MyError(error, errorType));
    };
}
